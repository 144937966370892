<template>
    <div class="study-view-container">
        <div class="hospital-info-container">
          <div class="hospital-name">检查报告单</div>
            <img class="hospital-icon" v-if="hasHospitalIcon" :src="`${hospitalIconUrl}`" />

        </div>
        <div class="patient-info-container">
            <!-- <img class="patient-icon" :src="`${imageUrl}person.png`" /> -->
            <div class="patient-info-box">
                <div class="patient-name">{{study.patientName}}</div>
                <div class="patient-age-sex">{{study.patientGender}}  {{study.patientAge}}{{study.patientAgeUnit}}</div>
            </div>
            <div class="study-time-box">
                <div class="study-time-label">检查时间</div>
                <div class="study-time">{{study.studyTime}}</div>
            </div>
        </div>
        <div class="section-container">
            <div class="section-name">基本信息</div>
            <div class="section-content">
                <div class="field-box">
                    <span class="field-name">检查号</span>
                    <span class="field-value">{{study.accessionNumber}}</span>
                </div>
                <div class="field-box">
                    <span class="field-name">检查项目</span>
                    <span class="field-value">{{study.pacsStudyItem}}</span>
                </div>
                <div class="field-box">
                    <span class="field-name">设备类型</span>
                    <span class="field-value">{{study.deviceType}}</span>
                </div>
                <!--
                <div class="field-box">
                    <span class="field-name">申请科室</span>
                    <span class="field-value">{{study.applyDepartmentName}}</span>
                </div>
                <div class="field-box">
                    <span class="field-name">病人主诉</span>
                    <span class="field-value">{{study.chiefComplaint}}</span>
                </div>
                -->
                <div class="field-box">
                    <span class="field-name">临床诊断</span>
                    <span class="field-value">{{study.clinicalDiagnose}}</span>
                </div>
            </div>
        </div>
        <div class="section-container">
            <div class="section-name">影像描述</div>
            <div class="section-content text-content">
                {{study.reportDesc}}
            </div>
        </div>
        <div class="section-container">
            <div class="section-name">报告诊断</div>
            <div class="section-content text-content">{{study.reportDiagnose}}</div>
            <div class="status_hint" v-if="!reportFinished">等待诊断</div>
        </div>
        <div class="section-container">
            <div class="section-name"></div>
            <div class="section-content">
                <div class="field-box">
                    <span class="field-name">报告时间</span>
                    <span class="field-value">{{study.reportTime}}</span>
                </div>
                <div class="field-box">
                    <span class="field-name">诊断医师</span>
                    <span class="field-value">{{study.doctorName1}}</span>
                </div>
                <div class="field-box">
                    <span class="field-name">审核医师</span>
                    <span class="field-value">{{study.doctorName2}}</span>
                </div>
            </div>
        </div>
        <div class="buttons-box">
            <el-button class="cmd-button" type="primary" v-if="allowCollectStudy" @click="collectStudy">收藏此检查</el-button>
            <el-button class="cmd-button" type="primary" v-if="isCollected" @click="cancelCollectStudy">取消收藏</el-button>
        </div>
        <div class="prompt-info">提示：此信息仅供参考。报告内容请以打印的纸质报告为准。</div>
        <div class="footBtn">
          <el-button
              v-if="study.printefilmstatus && study.printefilmstatus > 0"
              class="field-button"
              type="primary"
              @click="gotoFilm"
          >查看胶片</el-button>
          <el-button
              class="field-button"
              type="primary"
              v-if="reportFinished && study.showImage"
              @click="gotoImage"
          >
<!--            {{ (study.printefilmstatus && study.printefilmstatus > 0) ? '' : '查看'}}-->
            查看影像
          </el-button>
          <el-button
              class="field-button"
              type="primary"
              v-if="reportFinished && study.rptStorageId"
              @click="browseReport"
          >
<!--            {{ (study.printefilmstatus && study.printefilmstatus > 0) ? '' : '查看'}}-->
            查看报告
          </el-button>
          <el-button
              v-show="!reportFinished"
              disabled
          >等待诊断</el-button>
        </div>

        <ReportSnapshotView
            :study="study"
            v-if="reportViewVisible"
            :reportViewVisible.sync="reportViewVisible"
            v-on:closeView="closeReportView"
        />
        <FilmSnapshotView
            :study="study"
            v-if="filmViewVisible"
            :filmViewVisible.sync="filmViewVisible"
            v-on:closeView="closeReportView"
        ></FilmSnapshotView>
        <router-view></router-view>
    </div>
</template>

<script>
// import {StudyInfo} from '../../models/StudyInfo'
import ReportSnapshotView from './ReportSnapshotView.vue'
import FilmSnapshotView from './FilmSnapshotView.vue'
import {MessageProxy} from '../../utils/MessageProxy'
import { store } from '../../utils/store'

export default {
    name: 'StudyInfoView',
    components: {
        ReportSnapshotView,
        FilmSnapshotView
    },
    props: {
        risStudyId: String,
        study: {}
    },
    data() {
        return {
            reportViewVisible: false,
            imageUrl: store.imageUrl,
            // isCollected: false,
            patientNickname: "",
            hospitalIconUrl: "",
            hasHospitalIcon: false,
            reportFinished: true,
            filmHasFormatted: false,// 胶片已排版
            filmViewVisible: false,
        };
    },
    computed: {
        isDiscernable: function () {
            // alert(store.userId)
            if (store.userId != undefined && store.userId.trim().length > 0) {
                return true;
            }
            return false;
        },
        allowCollectStudy: function () {
            // alert(false == this.isDiscernable)
            if (false == this.isDiscernable) {
                return false;
            }
            // alert(this.study.isCollected)
            if (this.study != null) {
                if (undefined == this.study.isCollected || (this.study.isCollected != undefined && false == this.study.isCollected)) {
                    if (location.pathname.endsWith('/index.html')) {
                        return true;
                    }
                }
            }
            return false;
            // return this.isDiscernable == true && this.isCollected == false;
        },
        isCollected: function () {
            if (false == this.isDiscernable) {
                return false;
            }
            if (this.study != null && this.study.isCollected != undefined && true == this.study.isCollected) {
                if (location.pathname.endsWith('/index.html')) {
                    return true;
                }
            }
            return false;
        }
    },
    created() {
        // console.log("StudyInfoView risStudyId", this.risStudyId)

        // history.pushState("studyinfo", null);

        // window.addEventListener("popstate", (event) => {
        //     // console.log("StudyInfoView popstate", event.state)

        //     if ("studyinfo" == event.state) {
        //         this.reportViewVisible = false;
        //     }
        // })

        /*
        MessageProxy.getStudyById(store.userId, this.risStudyId).then((result)=>{
            if (true == result.hasError) {
                this.$message.error(result.errorText);
                return;
            }
            if (result.data != null && result.data.code != '200') {
                this.$message.error(result.data.message);
                return;
            }
            if (result.data.data != null) {
                this.study = result.data.data;
                if (this.study.isCollected != undefined && '1' == this.study.isCollected) {
                    this.isCollected = true;
                }
            }
            // console.log(this.study)
        })
        */

        /*
        let study = new StudyInfo();
        study.risStudyId = "1";
        study.accessionNumber = "CT20200821061";
        study.patientName = "王强";
        study.patientGender = "男";
        study.patientAge = 56;
        study.patientAgeUnit = "岁";
        study.deviceType = "CT";
        study.studyItem = "头颅平扫";
        study.studyTime = "2020-8-22";
        study.doctorName1 = "赵*";
        study.doctorName2 = "张*";
        study.reportDescription = "脑干内可见小片状稍高密度影，周围可见环形低密度影。双侧大脑半球对称，左侧基底节区及左顶叶可见斑片状低密度影，边界模糊。脑室系统对称，未见明显扩大或变小，中线结构居中，脑沟裂未见明显异常。";
        study.reportDiagnose = "原系“脑干出血（约1.2ml）；左侧基底节区及左顶叶见片状低密度影，不排除腔梗”现同前片（2018-01-11）比较脑干出血病灶密度减低；余未见明显变化。";
        study.reportTime = "2020-8-25"
        this.study = study;
        */
    },
    mounted() {
        let hospitalIconUrl = `${store.imageUrl}logo_${store.hospital.shortName}.png`;
        fetch(hospitalIconUrl).then((response) => {
            if (response.ok) {
                this.hospitalIconUrl = hospitalIconUrl;
                this.hasHospitalIcon = true;
            } else {
                this.hasHospitalIcon = false;
            }
        })
        if (this.study != null && this.study.studyStatus < window.config.studyStatus) {
            this.study.reportDesc = "";
            this.study.reportDiagnose = "";
            this.reportFinished = false;
        }
        // 胶片状态为2，显示查看已排版胶片按钮
        if (this.study.printefilmstatus && this.study.printefilmstatus == 2) {
            this.filmHasFormatted = true;
        }
    },
    methods: {
        collectStudy() {
            MessageProxy.collectStudy(store.userId, this.risStudyId, this.study.patientName, store.hospital.id).then((result)=>{
                if (true == result.hasError) {
                    this.$message.error(result.errorText);
                    return;
                }
                if (result.data != null && result.data.code != '200') {
                    this.$message.error(result.data.message);
                    return;
                }
                // this.isCollected = true;
                this.study.isCollected = true;
                this.$message.success("收藏成功。请到“关心的人”里查看收藏的病历。");
            })
        },
        cancelCollectStudy() {
            this.$confirm(`确定不再收藏这个检查吗？`, '取消收藏', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                MessageProxy.cancelCollectStudy(store.userId, this.risStudyId).then((result)=>{
                    if (true == result.hasError) {
                        this.$message.error(result.errorText);
                        return;
                    }
                    if (result.data != null && result.data.code != '200') {
                        this.$message.error(result.data.message);
                        return;
                    }
                    this.$message.success("取消成功。");

                    //刷新
                    // this.isCollected = false;
                    this.study.isCollected = false;
                })
            }).catch(() => {
            });
        },

        gotoFilm() {
            //
            // if (null == this.study.printefilmstatus) {
            //     this.$message.error("此检查无胶片快照。");
            //     return;
            // }
            this.filmViewVisible = true;
        },
        browseReport() {
            if (null == this.study.rptStorageId) {
                this.$message.error("此检查无报告单快照。");
                return;
            }
            // history.pushState('report', null);
            this.reportViewVisible = true;
            // this.$router.push('/report/' + this.study.risStudyId);
        },
        closeReportView() {
            this.reportViewVisible = false;
        },
        gotoImage() {
            this.$emit("gotoImage");
        }
    }
}
</script>

<style scoped>
.field-button{
  padding: 10px 12px;
  font-size: 4vmin;
}
    .study-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;
    }
    .hospital-info-container {
      background: linear-gradient(to bottom, #95c6fd 0%, #e5effd 100%);
        padding: 5vmin 0 3vmin 0;
        font-size: 5vmin;
        margin-bottom: 3vmin;
        text-align: center;
      align-items: center;
      justify-content: space-between;
      display: flex;
        /* box-shadow: 0px 12px 8px -12px #999; */
    }
    .hospital-info-container .hospital-icon {
      max-width: 32%;
      max-height: 8vmin;
      margin-right: 20px;
    }
    .hospital-info-container .hospital-name {
      font-size: 4.5vmin;
        text-align: center;
      color: #000;
      font-weight: bold;
      margin-left: 20px;
    }
    .patient-info-container {
        background-color: #fff;
        /* background: url(../../assets/person.png) #fff 5vmin 10% no-repeat;
        background-size: auto 80%; */
        padding: 2vmin 0vmin 2vmin 5vmin;
        font-size: 4vmin;

        display: flex;
    }
    .patient-info-container .patient-icon {
        height: 12vmin;
        margin: 0 5vmin;
    }
    .patient-info-container .patient-info-box {
        flex-grow: 1;
    }
    .patient-info-container .patient-info-box .patient-name {
        font-size: 4.5vmin;
        font-weight: bold;
    }
    .patient-info-container .study-time-box {
        flex-grow: 1;
    }
    .patient-info-container .study-time-box .study-time-label {
        font-size: 4vmin;
        color: #999;
    }
    .patient-info-container .study-time-box .study-time {
        font-weight: bold;
        padding-top: 1vmin;
    }

    .study-view-container .field-box {
        display: flex;
    }

    .study-view-container .field-name {
        font-size: 3.5vmin;
        color: #999;
        width: 20vmin;
        padding: 2vmin 0;
    }
    .study-view-container .field-value {
        padding: 2vmin 0;
        flex: 1 1 0;
        border-bottom: 1px solid #e0e0e0;
    }

    .section-container .section-name {
        padding: 2vmin 5vmin;
        color: #999;
    }
    .section-container .section-content {
        background-color: #fff;
        padding: 2vmin 5vmin 3vmin 5vmin;
    }
    .section-container .text-content {
        white-space: pre-wrap;
    }

    .study-view-container .buttons-box {
        padding: 2vmin 1vmin;
    }
    .study-view-container .cmd-button {
        width: 100%;
        margin: 1vmin 0;
    }

    .study-view-container .prompt-info {
        padding: 3vmin 5vmin;
        color: red;
        font-size: 3vmin;
      margin-bottom: 15vmin;
    }
    .status_hint {
        font-size: 10vmin;
        color: blue;

        position: absolute;
        left: 50%;
        transform:translateX(-50%) rotate(-35deg) scaleX(1.2);
        z-index: 999;
    }

    .footBtn{
      padding: 1.5vmin 0;
      text-align: center;
      left: 0;
      bottom: 0;
      width: 100%;
      position: fixed;
      z-index: 0;
      background: #fff;
      box-shadow: 0 0 10px rgba(43, 45, 54, 0.15);
    }

</style>
